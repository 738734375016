@media (max-width: 1680px) and (min-width: 1280px) {
  .inner {
    width: 1170px;
  }
  :root {
    --prodGap: 15px;
    --rowGap: 64px;
  }
  .header-tools-wrapper {
    gap: 24px;
  }
  #main-bar,
  .mainmenu,
  #tree {
    gap: 18px;
  }
  .mainpage-greeting-wrapper .mainpage-greeting {
    padding: 0 5% 0 0;
  }
  .alternating-banner .text-wrapper {
    padding: 0 0 0 5%;
  }
  .alternating-banner:nth-child(2n) .text-wrapper {
    padding: 0 5% 0 0;
  }
  #footer-poll {
    flex-basis: 14.5%;
  }
  .contact-wrapper {
    column-gap: 44px;
  }
  .product .product-info-wrapper {
    padding: 64px 64px 64px 90px;
  }
  .product:nth-child(2n+1) .product-info-wrapper {
    padding: 64px 90px 64px 64px;
  }
  body[data-type="listing"].products-list {
    gap: 80px;
  }
  .listing-wrapper .pagination {
    margin-top: var(--rowGap);
  }
  .product-images,
  .product-images-mini {
    width: 100%;
  }
  .detail-main-wrapper {
    gap: 0 80px;
  }
  .detail-main-wrapper #detail-images {
    grid-row: 1 / span 3;
  }
  .detail-main-wrapper #detail-right {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    margin-bottom: 24px;
  }
  .detail-main-wrapper .detail-left {
    grid-column: 2 / 4;
    grid-row: 3 / 4;
    margin-right: 0;
  }
  .detail-section-picker {
    gap: 26px;
  }
  .detail-section-picker .headline {
    font-size: 21px !important;
  }
}
@media (max-width: 1279px) {
  :root {
    --rowGap: 25px;
    --fontSize: 13px;
    --lineHeight: 1.4;
  }
  .detail-omnibus {
    align-self: flex-start;
    margin-top: 0;
    font-size: 11px;
  }
  .footer-bottom-bar {
    padding: 30px 0 100px;
  }
  .detail-omnibus {
    text-align: left;
  }
  main {
    padding-bottom: var(--rowGap) !important;
  }
  main.aside {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: var(--rowGap) 0;
  }
  .inner {
    width: 100%;
    padding: 0 10px;
  }
  * {
    -webkit-tap-highlight-color: rgba(189, 195, 199, 0.75);
    -webkit-text-size-adjust: none;
  }
  body.privacy-box-visible {
    overflow: hidden;
  }
  body.privacy-box-visible #container {
    filter: blur(5px);
    pointer-events: none;
  }
  body:has(.left-side-cookies) .privacy-box-trigger {
    bottom: 15px;
    width: 35px;
    height: 35px;
  }
  body:has(.left-side-cookies) .privacy-box-trigger.right-side-cookies {
    right: 15px;
  }
  body:has(.left-side-cookies) .privacy-box-trigger.left-side-cookies {
    left: 15px;
  }
  .privacy-box {
    bottom: auto;
    right: auto;
    left: 15px !important;
    right: 15px !important;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100vw;
    max-height: calc(100% - 40px);
  }
  input[type=text],
  input[type=password],
  input[type=email],
  select,
  textarea {
    font-size: 16px;
    padding: 0 14px;
  }
  textarea {
    padding: 10px 14px;
  }
  #facebook-box,
  .detail-loupe {
    display: none;
  }
  #detail-images .swiper-button-prev,
  #detail-images .swiper-button-next {
    height: 80px;
    margin-top: -40px !important;
  }
  #detail-images .swiper-button-prev {
    left: -45px;
  }
  #detail-images .swiper-button-next {
    right: -45px;
  }
  .headline {
    font-size: 24px !important;
    margin-bottom: 10px;
  }
  #content {
    width: 100%;
    clear: both;
  }
  .sidebar {
    width: auto;
    display: contents;
  }
  .sidebar .sidebar-tabs {
    display: none;
  }
  #search-engine {
    display: none;
  }
  .newsletter-input {
    width: 100%;
  }
  .footer-content {
    flex-direction: column;
    padding: 20px 0;
  }
  .footer-item {
    width: 100% !important;
  }
  body:has(.gs-filtr-container) .filter-trigger {
    display: flex;
  }
  .filter-trigger,
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button {
    height: 40px;
    align-items: center;
    justify-content: space-between;
    font-weight: normal;
    font-size: 13px;
    gap: 5px;
    padding: 0 25px;
    min-width: 0;
    font-weight: bold;
    color: #202124;
    border-radius: 20px;
    border-color: #202124;
  }
  .filter-trigger:before,
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button:before {
    display: none;
  }
  .filter-trigger svg,
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button svg {
    position: static;
    transform: rotate(90deg);
    height: auto;
    width: 20px;
    filter: brightness(0);
  }
  .dropdown-picker:before {
    top: -11px;
    font-size: 12px;
  }
  .listing-wrapper .filter-trigger,
  .listing-wrapper .dropdown-picker {
    margin-top: 11px;
  }
  .filter-trigger:after {
    content: 'i sortowanie';
  }
  .dropdown-picker[data-dropdown-type="sort"] {
    display: none;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]):before {
    display: block;
    color: #202124;
    font-weight: normal;
    left: 17px;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button {
    min-width: 160px;
    border-color: #202124;
    font-weight: normal;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button:after {
    color: #202124;
    font-weight: normal;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-listing {
    border-radius: 20px;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-listing label {
    padding: 5px 25px;
  }
  #filter {
    display: none;
  }
  .product-images-mini {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
  .product-images-mini .swiper-wrapper {
    transform: none !important;
    width: auto;
    justify-content: center;
  }
  .product-images-mini .swiper-slide {
    width: 10px;
    height: 10px;
    background-color: #202124;
    border-radius: 50%;
    margin: 0 5px;
  }
  .product-images-mini img {
    display: none !important;
  }
  .user-custom-content p {
    margin-bottom: 10px;
  }
  hr {
    margin: 20px 0;
  }
  .overlay-visible {
    overflow: hidden;
    height: 100dvh;
    width: 100dvw;
    position: fixed;
    top: 0;
  }
  main:not(.aside) #tree {
    display: block;
  }
  .tree-canvas {
    position: fixed;
    background: #000000 none repeat scroll 0% 0%;
    will-change: opacity;
    transition: opacity 300ms ease 0s;
    inset: 0px;
    z-index: 1001;
    pointer-events: none;
    opacity: 0;
  }
  #tree {
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(-100%, 0, 0);
    overflow: hidden;
  }
  :root {
    --prodGap: 15px;
    --rowGap: 30px;
    --iconSize: 18px;
  }
  .top-bar,
  .header-bar {
    padding-block: 4px;
  }
  .header-shipment {
    gap: 8px;
  }
  .header-shipment b {
    margin-left: -4px;
  }
  .header-contact {
    gap: 46px;
  }
  .header-contact a:before {
    left: -27px;
  }
  .header-social-links {
    gap: 8px;
  }
  .header-social-links a:before {
    font-size: 22px;
  }
  .logo {
    height: 44px;
  }
  .mainmenu {
    display: none !important;
  }
  .shop-header-container {
    padding: 8px 0;
  }
  .header-tools-wrapper {
    gap: 24px;
  }
  .shop-header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
  }
  #cart-box,
  #wishlist-box,
  .log-button,
  .search-engine-trigger,
  .tree-trigger {
    padding-top: 28px;
  }
  #cart-box:before,
  #wishlist-box:before,
  .log-button:before,
  .search-engine-trigger:before,
  .tree-trigger:before {
    font-size: 22px;
  }
  #cart-box em,
  #wishlist-box em,
  .log-button em,
  .search-engine-trigger em,
  .tree-trigger em {
    letter-spacing: 0.1em;
    font-size: 9px;
    font-weight: 600;
    display: block;
    color: #202124;
  }
  .hamburger-icon {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 22px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    align-items: center;
  }
  .hamburger-icon span {
    display: block;
    width: 80%;
    height: 2px;
    background-color: #202124;
    position: relative;
  }
  .hamburger-icon span:nth-child(3):after {
    content: '';
    position: absolute;
    top: 0;
    left: 4px;
    background: white;
    height: 2px;
    width: 2px;
  }
  .hamburger-icon span:nth-child(3):after {
    right: 5px;
    left: initial;
  }
  #wishlist-box:after,
  #cart-box i {
    font-size: 0px;
    color: var(--mainColor);
    width: 8px;
    height: 8px;
    top: 1px;
    right: 9px !important;
  }
  #cart-box i {
    right: 4px !important;
  }
  .newsletter-input .popup-trigger,
  .text-expand-content + button,
  .main-banner-text-container .text-wrapper > a,
  .alternating-banner .text-wrapper > a,
  .blog-banner > a,
  body[data-type="detail"] form button {
    font-size: 12px !important;
    padding: 10px 24px !important;
    border-radius: 8px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 32px;
    height: 32px;
    margin-top: -16px;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 14px;
  }
  .main-banner-wrapper {
    margin-bottom: calc(var(--rowGap) + 54px);
  }
  .main-banner .swiper-slide {
    height: auto;
    aspect-ratio: 1440/600;
  }
  .main-slider-buttons-wrapper {
    --swiper-navigation-sides-offset: 10px;
  }
  .main-banner-text-container .text-wrapper {
    bottom: 32px;
  }
  .main-banner-text-container .text-wrapper > b {
    font-size: 38px;
  }
  .main-banner-text-container .text-wrapper > span {
    font-size: 16px;
  }
  .main-banner-text-container .text-wrapper > a {
    margin-top: 18px;
  }
  .mainpage-greeting-wrapper .mainpage-greeting {
    padding: 0 2% 0 0;
  }
  .alternating-banner .text-wrapper {
    padding: 0 0 0 2%;
    margin-block: var(--rowGap);
  }
  .alternating-banner:nth-child(2n) .text-wrapper {
    padding: 0 2% 0 0;
  }
  .alternating-banner .text-wrapper > b {
    font-size: 24px !important;
    margin-bottom: 10px;
  }
  .alternating-banner .text-wrapper > div {
    padding-bottom: 18px;
  }
  .main-page-products-wrapper,
  #detail-tabs {
    --swiper-navigation-sides-offset: 0px;
  }
  .main-page-products-wrapper .product,
  #detail-tabs .product {
    padding-bottom: 16px;
  }
  .main-page-products-wrapper a.product-name,
  #detail-tabs a.product-name {
    --fontSize: 16px;
    font-size: 16px;
  }
  .main-page-products-wrapper .product-price,
  #detail-tabs .product-price {
    margin-top: 12px;
    gap: 12px;
  }
  .main-page-products-wrapper .product-price b,
  #detail-tabs .product-price b {
    font-size: 20px;
  }
  .main-page-products-wrapper .product-price del,
  #detail-tabs .product-price del {
    font-size: 16px;
    top: -2px;
  }
  .main-page-products-wrapper .headline {
    font-size: 24px !important;
    margin-bottom: 10px;
  }
  .promo-banners-wrapper {
    margin-bottom: 54px;
    gap: 24px;
  }
  .promo-banner figure {
    width: 48px;
    margin-block: 20px 16px;
  }
  .promo-banner b {
    font-size: 15px;
  }
  .blog-banners-section .headline {
    margin-bottom: 20px;
  }
  .blog-banner > * {
    margin-left: 0 !important;
  }
  .blog-banner figure {
    margin-bottom: 12px;
  }
  .blog-banner > b {
    font-size: 16px;
  }
  .blog-banner > div {
    margin-bottom: 12px;
  }
  .blog-banner > a {
    margin-bottom: 12px;
  }
  .main-page-products-wrapper .slider-button1 {
    top: calc(50% - 62px);
    --swiper-navigation-sides-offset: 5px;
  }
  .side-mascot {
    top: 136px;
    width: 144px;
  }
  #newsletter {
    height: auto;
    align-items: center;
  }
  .newsletter-wrapper {
    margin-block: var(--rowGap);
    padding: var(--rowGap);
  }
  .newsletter-wrapper .newsletter-header b {
    font-size: 26px;
    margin-bottom: 12px;
  }
  .newsletter-input input[type="text"] {
    height: 44px;
  }
  .footer-content {
    padding: 24px 0;
  }
  .footer-logo {
    margin-top: 0;
    margin-bottom: 8px;
    width: 120px;
  }
  .footer-item > strong {
    margin-bottom: 16px;
  }
  .footer-item.submenu ul {
    gap: 0;
  }
  #footer-poll ul li {
    gap: 10px;
  }
  #footer-poll ul li[data-poll-vote-id]:before {
    width: 19px;
    height: 19px;
  }
  #footer-poll ul li[data-poll-vote-id]:after {
    width: 11px;
    height: 11px;
    margin: 0 4px;
  }
  .contact-wrapper {
    columns: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .contact-wrapper > * {
    margin-bottom: 0 !important;
  }
  .footer-bottom-bar {
    flex-direction: column;
    gap: 16px;
    padding: 24px 0 83px;
  }
  #breadcrumb {
    padding-block: 10px;
    margin-bottom: calc(-0.5 * var(--rowGap) + 54px);
  }
  .text-expand-wrapper:has(.expandable) {
    padding-bottom: 50px;
  }
  .listing-wrapper {
    gap: 16px;
  }
  .listing-wrapper .navigation-wrapper {
    margin-bottom: 0;
  }
  body[data-type="listing"] .products-list {
    gap: 0;
  }
  body[data-type="listing"] .product-info-wrapper,
  body[data-type="listing"] .product-image {
    margin-block: var(--rowGap) !important;
  }
  body[data-type="listing"] .product .product-info-wrapper {
    margin-inline: 7% 0;
    padding: 32px 64px !important;
  }
  body[data-type="listing"] .product .product-info-wrapper .product-wishlist {
    left: 10px;
    top: 10px;
    width: 32px;
    height: 32px;
  }
  body[data-type="listing"] .product .product-info-wrapper .product-wishlist:before {
    font-size: 18px;
  }
  body[data-type="listing"] .product .product-info-wrapper .product-name {
    font-size: 18px;
  }
  body[data-type="listing"] .product .product-info-wrapper .product-desc {
    font-size: var(--fontSize);
  }
  body[data-type="listing"] .product .product-info-wrapper .product-price b {
    font-size: 18px;
  }
  body[data-type="listing"] .product .product-info-wrapper .product-price del {
    margin-left: 8px;
  }
  body[data-type="listing"] .product .product-info-wrapper .see-more {
    font-size: 14px;
    height: 38px;
  }
  body[data-type="listing"] .product .product-info-wrapper .see-more span {
    padding: 0 28px;
  }
  body[data-type="listing"] .product .product-info-wrapper .see-more:before,
  body[data-type="listing"] .product .product-info-wrapper .see-more:after {
    bottom: -11%;
    height: 32px;
  }
  body[data-type="listing"] .product:nth-child(2n+1) .product-info-wrapper {
    margin-inline: 0 7%;
  }
  .listing-wrapper .pagination {
    gap: 32px;
  }
  .detail-main-wrapper {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
  #detail-images {
    align-items: center;
    flex-direction: column;
    height: auto;
    left: auto;
    margin: 0 auto;
    position: relative;
    top: auto;
    width: 100%;
  }
  #detail-images .swiper-button-prev {
    left: 10px;
    height: 32px;
  }
  #detail-images .swiper-button-next {
    right: 10px;
    height: 32px;
  }
  .product-images-mini {
    order: initial;
    height: auto;
    width: 100%;
  }
  .product-images-mini .size-custom {
    padding: 0 !important;
    background-color: var(--mainColor);
  }
  .detail-headline {
    text-align: center;
  }
  .detail-left,
  #detail-right {
    gap: 20px !important;
  }
  #detail-traits .trait {
    gap: 24px;
  }
  #detail-traits .trait b {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }
  #detail-traits .trait .trait-desc {
    flex: 1;
  }
  .detail-wishlist {
    padding-left: 30px;
  }
  .detail-wishlist:before {
    font-size: 20px;
  }
  #detail-right {
    padding: 24px;
  }
  .price-wrapper {
    justify-content: center;
  }
  .detail-pricing {
    padding-bottom: 20px;
  }
  .detail-adding .buy-button,
  .detail-adding .availability-modal-trigger,
  .detail-adding .detail-amount input {
    border-radius: 8px;
  }
  .detail-adding .detail-amount {
    margin-right: 8px;
  }
  .detail-main-info {
    margin-top: 0 !important;
  }
  .detail-section-picker {
    margin-bottom: 20px;
    gap: 24px;
    top: 90px;
  }
  .detail-section-picker .headline {
    font-size: 20px !important;
  }
  .comments-list {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .comment > cite {
    padding: 20px;
    margin-bottom: 12px;
  }
  .comment-response {
    margin-block: 12px 6px;
    padding: 20px;
  }
  .under-comment .added-date {
    padding-left: 12px;
    margin-left: 0;
  }
  .under-comment .purchase-confirmed {
    gap: 6px;
  }
  body[data-type="detail"] form {
    gap: 16px;
    margin-bottom: 10px;
  }
  body[data-type="detail"] form button {
    height: 38px;
  }
  #detail-tabs nav {
    margin-bottom: 18px !important;
    gap: 16px;
  }
  #detail-tabs nav button {
    font-size: 24px;
  }
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    .header-tools-wrapper > * {
      margin-left: 10px;
    }
    .hamburger-icon {
      display: grid;
      grid-auto-rows: max-content;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 6px);
      gap: 0;
      justify-items: center;
      align-items: center;
    }
    .tree-canvas {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
@media (max-width: 1100px) {
  .comments-list {
    gap: 20px;
  }
  .comment .author:before {
    display: none;
  }
  .comment .added-date {
    margin-left: 0;
    width: 100%;
  }
  .comment .purchase-confirmed {
    width: 100%;
  }
  #detail-traits td {
    padding: 8px 15px;
  }
  .header-shipment {
    display: none !important;
  }
  .header-bar {
    justify-content: space-between;
  }
  .product-badges span {
    font-size: 12px;
  }
  .newsletter-input .popup-trigger,
  .text-expand-content + button,
  .main-banner-text-container .text-wrapper > a,
  .alternating-banner .text-wrapper > a,
  .blog-banner > a,
  body[data-type="detail"] form button {
    font-size: 10px !important;
    padding: 8px 16px !important;
  }
  .main-banner-text-container .text-wrapper {
    bottom: 16px;
  }
  .main-banner-text-container .text-wrapper > b {
    font-size: 28px;
    text-shadow: 1px 1px 0px #fff, -1px 1px 0px #fff, -1px -1px 0px #fff, 1px -1px 0px #fff, -1px 0px 0px white, 1px 0px 0px white, 0px -2px 0px white, 0px 1px 0 white;
  }
  .main-banner-text-container .text-wrapper > span {
    font-size: 14px;
  }
  .main-banner-text-container .text-wrapper > a {
    margin-top: 4px;
  }
  .main-banner .banner-img-wrapper:after {
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 40%);
  }
  .side-mascot {
    top: 115px;
    width: 100px;
  }
  .newsletter-header b {
    text-shadow: 1px 1px 0px #fff, -1px 1px 0px #fff, -1px -1px 0px #fff, 1px -1px 0px #fff, -1px 0px 0px white, 1px 0px 0px white, 0px -2px 0px white, 0px 1px 0 white;
  }
  #detail-traits .trait {
    flex-direction: column;
    gap: 6px;
    padding: 12px 0;
  }
  #detail-traits .trait b {
    width: 100%;
    justify-content: flex-start;
  }
  #detail-traits .trait .trait-desc {
    width: 100%;
  }
  .under-comment {
    padding: 0;
    font-size: 12px;
    gap: 13px;
  }
  .under-comment .added-date {
    padding-left: 13px;
  }
  .under-comment .purchase-confirmed {
    gap: 8px;
  }
  .under-comment .purchase-confirmed:before {
    font-size: 18px;
  }
  .comment-response {
    margin-left: 16px;
  }
}
@media (max-width: 900px) {
  .mainpage-greeting-wrapper,
  .alternating-banner,
  body[data-type="listing"] .product {
    flex-direction: column;
    align-items: center !important;
    text-align: center !important;
    gap: 16px !important;
  }
  .mainpage-greeting-wrapper figure,
  .alternating-banner figure,
  body[data-type="listing"] .product figure {
    order: -1 !important;
    max-width: 500px !important;
    width: 100% !important;
    align-self: initial;
    padding: 0 !important;
    margin: 0 !important;
  }
  .mainpage-greeting-wrapper .text-wrapper,
  .alternating-banner .text-wrapper,
  body[data-type="listing"] .product .text-wrapper,
  .mainpage-greeting-wrapper .product-info-wrapper,
  .alternating-banner .product-info-wrapper,
  body[data-type="listing"] .product .product-info-wrapper {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mainpage-greeting-wrapper .product-info-wrapper,
  .alternating-banner .product-info-wrapper,
  body[data-type="listing"] .product .product-info-wrapper {
    padding: 32px !important;
  }
  .mainpage-greeting-wrapper .product-info-wrapper .product-badges,
  .alternating-banner .product-info-wrapper .product-badges,
  body[data-type="listing"] .product .product-info-wrapper .product-badges {
    flex-direction: row;
  }
  .alternating-banner {
    padding-block: calc(0.5 * var(--rowGap));
  }
  body[data-type="listing"] .product {
    padding-block: 20px;
  }
  body[data-type="listing"] .product .product-info-wrapper {
    margin-bottom: 24px !important;
    width: 100%;
    max-width: 700px !important;
  }
  body[data-type="listing"] .product .product-info-wrapper .product-price {
    margin-top: 18px;
  }
  body[data-type="listing"] .product .product-info-wrapper .product-desc {
    margin-top: 14px;
  }
  body[data-type="listing"] .product .product-info-wrapper .product-name {
    padding: 0 20px;
  }
  body[data-type="listing"] .product .product-info-wrapper .product-price {
    margin-bottom: 6px;
  }
}
@media (max-width: 600px) {
  .detail-share-links-wrapper span {
    width: 100%;
  }
  .stars-rating.average-rating {
    top: -2px;
  }
  .gs-tabs nav {
    flex-direction: column;
  }
  .gs-tabs nav button {
    width: 100%;
  }
  .user-custom-content iframe {
    max-width: 100%;
  }
  .filter-trigger {
    padding: 0 20px;
    justify-content: flex-start;
    gap: 4px;
    width: fit-content;
  }
  .dropdown-picker[data-dropdown-type="pageSize"] {
    flex-grow: 1;
  }
  .dropdown-picker[data-dropdown-type="pageSize"] .dropdown-button {
    width: 100%;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]):before {
    left: 13px;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button {
    padding: 0 36px 0 20px !important;
    min-width: 0;
  }
  .privacy-box {
    padding: 10px;
    gap: 10px;
  }
  .privacy-box__header {
    gap: 10px;
  }
  .privacy-box__header img {
    height: 20px;
  }
  .privacy-box__header strong {
    font-size: 14px;
  }
  .privacy-box__actions {
    flex-wrap: wrap;
  }
  .privacy-box__actions button {
    min-height: 35px;
  }
  .privacy-box__actions button.privacy-accept-all {
    width: 100%;
    order: -1;
    flex: 0 0 auto;
    min-height: 45px;
  }
  .pagination {
    gap: 4px;
  }
  .pagination a {
    width: 30px;
    height: 30px;
  }
  #breadcrumb {
    display: flex;
    margin-left: -10px;
    width: calc(100% + 20px);
    justify-content: flex-start;
    position: relative;
  }
  #breadcrumb .breadcrumb-end {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 20px;
    pointer-events: none;
  }
  #breadcrumb .breadcrumb-end:nth-of-type(1) {
    left: -7px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb .breadcrumb-end:nth-of-type(2) {
    right: -7px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb ul {
    flex-wrap: nowrap;
    overflow-y: auto;
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding-bottom: 5px;
  }
  #breadcrumb li {
    white-space: nowrap;
  }
  #breadcrumb li:last-of-type {
    padding-right: 10px;
  }
  #breadcrumb li:first-of-type {
    padding-left: 10px;
  }
  #breadcrumb li:nth-of-type(1) {
    order: 10;
  }
  #breadcrumb li:nth-of-type(2) {
    order: 9;
  }
  #breadcrumb li:nth-of-type(3) {
    order: 8;
  }
  #breadcrumb li:nth-of-type(4) {
    order: 7;
  }
  #breadcrumb li:nth-of-type(5) {
    order: 6;
  }
  #detail-images .swiper-button-prev {
    left: 0;
  }
  #detail-images .swiper-button-next {
    right: 0;
  }
  #gsGalleryContainer > a {
    width: 100%;
  }
  .navigation-wrapper {
    padding-top: 0;
    border-top: 0;
  }
  .availability-modal-wrapper .wrapper {
    justify-content: center;
    gap: 10px;
  }
  .availability-modal-wrapper .wrapper > p {
    text-align: center;
  }
  .availability-modal-wrapper .wrapper > .availability-product {
    justify-content: center;
  }
  .availability-modal-wrapper .availability-product {
    gap: 10px;
    padding: 10px 0;
  }
  .availability-modal-wrapper .input-wrapper {
    flex-wrap: wrap;
  }
  .availability-modal-wrapper .input-wrapper > * {
    width: 100%;
  }
  .product-images {
    width: 100%;
  }
  .product-images .size-full {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
  .detail-amount {
    width: 100%;
    margin: 0 0 8px 0 !important;
  }
  .pills-group:before,
  .remove-all-filters {
    font-size: 11px;
  }
  .single-pill {
    font-size: 11px;
    padding: 2px 5px;
  }
  #pills-container:not(:empty) {
    margin-bottom: 20px;
  }
  :root {
    --rowGap: 25px;
  }
  .headline,
  .main-page-products-wrapper .headline,
  .alternating-banner .text-wrapper > b,
  #detail-tabs nav button {
    font-size: 20px !important;
  }
  .main-banner-text-container .text-wrapper {
    bottom: 10px;
  }
  .main-banner-text-container .text-wrapper > b {
    font-size: 18px;
  }
  .main-banner-text-container .text-wrapper > span {
    display: none;
  }
  .main-banner-text-container .text-wrapper > a {
    margin-top: 0;
  }
  .side-mascot {
    display: none !important;
  }
  .blog-banners-wrapper {
    gap: 12px;
  }
  .blog-banner b {
    font-size: 14px;
  }
  .main-page-products-wrapper a.product-name,
  #detail-tabs a.product-name {
    --fontSize: 14px;
    font-size: 14px;
  }
  .newsletter-wrapper .newsletter-header b {
    font-size: 20px !important;
  }
  body[data-type="listing"] .product .product-info-wrapper .see-more span {
    white-space: nowrap;
  }
  .detail-share-links-wrapper span {
    display: flex;
    justify-content: center;
  }
  .price-wrapper {
    gap: 8px;
  }
  .detail-section-picker {
    gap: 12px;
    padding: 16px 8px;
  }
  .detail-section-picker .headline {
    font-size: 16px !important;
  }
  .main-info-sections .headline {
    font-size: 18px !important;
  }
  .under-comment {
    display: grid;
    grid-auto-rows: max-content;
    grid-template-columns: minmax(0, 55fr) minmax(0, 45fr);
    padding: 0;
    gap: 6px;
    margin-top: -4px;
  }
  .under-comment .author {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    align-self: end;
    position: relative;
    top: -3px;
  }
  .under-comment .stars-rating {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    margin-left: auto;
  }
  .under-comment .added-date {
    margin: 0;
    padding: 0;
    border-left: none;
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    align-self: start;
  }
  .under-comment .purchase-confirmed {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    white-space: nowrap;
  }
  .under-comment .purchase-confirmed:before {
    display: none;
  }
}
@media (max-width: 600px) and (max-width: 420px) {
  .top-bar {
    font-size: 11px;
  }
  .header-contact:has(.email) .phone {
    display: none;
  }
  .header-contact:has(.email) .email {
    display: block;
  }
  .header-contact:has(.phone) .email {
    display: none;
  }
  .header-contact:has(.phone) .phone {
    display: block !important;
  }
  .shop-header-container {
    flex-direction: column;
    gap: 4px;
    padding-block: 6px 6px;
  }
  .header-tools-wrapper {
    width: 100%;
    justify-content: space-evenly;
  }
  .detail-section-picker {
    top: 134px;
  }
}
@media (max-width: 600px) and (max-width: 420px) {
  .blog-banners-wrapper {
    grid-template-columns: minmax(0, 1fr);
  }
}
@media (max-width: 600px) and (max-width: 360px) {
  .price-wrapper b {
    font-size: 24px;
  }
}
@media (max-width: 600px) and (max-width: 460px) {
  .detail-section-picker {
    padding: 16px 0;
    gap: 8px;
  }
  .detail-section-picker:before {
    content: "";
    background-color: #202124;
    position: absolute;
    width: 101vw;
    height: 100%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    background: #FFEAB3;
  }
  .detail-section-picker .headline {
    font-size: 14px !important;
  }
}
@media (max-width: 600px) and (max-width: 460px) and (max-width: 410px) {
  .detail-section-picker {
    gap: 6px;
  }
  .detail-section-picker .headline {
    font-size: 12px !important;
  }
}
