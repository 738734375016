// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1100px) {
    .comments-list {
        gap: 20px;
    }

    .comment .author:before {
        display: none;
    }

    .comment .added-date {
        margin-left: 0;
        width: 100%;
    }

    .comment .purchase-confirmed {
        width: 100%;
    }

    #detail-traits td {
        padding: 8px 15px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    // 1100 BASE
    .header-shipment {
        display: none !important;
    }

    .header-bar {
        justify-content: space-between;
    }

    .product-badges span {
        font-size: 12px;
    }

    .newsletter-input .popup-trigger,
    .text-expand-content + button,
    .main-banner-text-container .text-wrapper > a,
    .alternating-banner .text-wrapper > a,
    .blog-banner > a,
    body[data-type="detail"] form button {
        font-size: 10px !important;
        padding: 8px 16px !important;
    }

    // 1100 MAINPAGE
    .main-banner-text-container .text-wrapper {
        bottom: 16px;

        > b {
            font-size: 28px;
            text-shadow:
                1px 1px 0px #fff,
                -1px 1px 0px #fff,
                -1px -1px 0px #fff,
                1px -1px 0px #fff,
                -1px 0px 0px white,
                1px 0px 0px white,
                0px -2px 0px white,
                0px 1px 0 white;
        }

        > span {
            font-size: 14px;
        }

        > a {
            margin-top: 4px;
        }
    }

    .main-banner .banner-img-wrapper:after {
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 40%);
    }

    .side-mascot {
        top: 115px;
        width: 100px;
    }

    // 1100 FOOTER
    .newsletter-header b {
        text-shadow:
            1px 1px 0px #fff,
            -1px 1px 0px #fff,
            -1px -1px 0px #fff,
            1px -1px 0px #fff,
            -1px 0px 0px white,
            1px 0px 0px white,
            0px -2px 0px white,
            0px 1px 0 white;
    }

    // 1100 DETAIL

    #detail-traits .trait {
        flex-direction: column;
        gap: 6px;
        padding: 12px 0;

        b {
            width: 100%;
            justify-content: flex-start;
        }

        .trait-desc {
            width: 100%;
        }
    }

    .under-comment {
        padding: 0;
        font-size: 12px;
        gap: 13px;

        .added-date {
            padding-left: 13px;
        }

        .purchase-confirmed {
            gap: 8px;

            &:before {
                font-size: 18px;
            }
        }
    }

    .comment-response {
        margin-left: 16px;
    }
}

@media (max-width: 900px) {

    .mainpage-greeting-wrapper,
    .alternating-banner,
    body[data-type="listing"] .product {
        flex-direction: column;
        align-items: center !important;
        text-align: center !important;
        gap: 16px !important;

        figure {
            order: -1 !important;
            max-width: 500px !important;
            width: 100% !important;
            align-self: initial;
            padding: 0 !important;
            margin: 0 !important;
        }

        .text-wrapper,
        .product-info-wrapper {
            margin: 0 !important;
            padding: 0 !important;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .product-info-wrapper {
            padding: 32px !important;

            .product-badges {
                flex-direction: row;
            }
        }
    }

    .alternating-banner {
        padding-block: calc(0.5 * var(--rowGap));
    }

    body[data-type="listing"] .product {
        padding-block: 20px;

        .product-info-wrapper {
            margin-bottom: 24px !important;
            width: 100%;
            max-width: 700px !important;

            .product-price {
                margin-top: 18px;
            }

            .product-desc {
                margin-top: 14px;
            }

            .product-name {
                padding: 0 20px;
            }
            .product-price {
                margin-bottom: 6px;
            }
        }
    }
}