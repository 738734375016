// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1680px) and (min-width: 1280px) {
    .inner {
        width: 1170px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    :root {
        --prodGap: 15px;
        --rowGap: 64px;
    }

    // 1680 BASE
    .header-tools-wrapper {
        gap: 24px;
    }

    #main-bar, .mainmenu, #tree {
        gap: 18px;
    }

    // 1680 MAINPAGE
    .mainpage-greeting-wrapper .mainpage-greeting {
        padding: 0 5% 0 0;
    }

    .alternating-banner .text-wrapper {
        padding: 0 0 0 5%;
    }

    .alternating-banner:nth-child(2n) .text-wrapper {
        padding: 0 5% 0 0;
    }

    .main-page-products-wrapper .slider-button1 { 
    }

    // 1680 FOOTER
    #footer-poll {
        flex-basis: 14.5%;
    }

    .contact-wrapper {
        column-gap: 44px;
    }

    // 1680 LISTING

    .product .product-info-wrapper {
        padding: 64px 64px 64px 90px;
    }

    .product:nth-child(2n+1) .product-info-wrapper {
        padding: 64px 90px 64px 64px;
    }

    body[data-type="listing"].products-list {
        gap: 80px;
    }

    .listing-wrapper .pagination {
        margin-top: var(--rowGap);
    }

    // 1680 DETAIL

    .product-images,
    .product-images-mini {
        width: 100%;
    }

    .detail-main-wrapper {
        gap: 0 80px;

        #detail-images {
            grid-row: 1 / span 3;
        }

        #detail-right {
            grid-column: 2 / 4;
            grid-row: 2 / 3;
            margin-bottom: 24px;
        }

        .detail-left {
            grid-column: 2 / 4;
            grid-row: 3 / 4;
            margin-right: 0;
        }
    }

    .detail-section-picker { 
        gap: 26px;

        .headline {
            font-size: 21px !important;
        }
    }


}