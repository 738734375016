// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1279px) {
    :root {
        --rowGap: 25px;
        --fontSize: 13px;
        --lineHeight: 1.4;
    }

    .detail-omnibus {
        align-self: flex-start;
        margin-top: 0;
        font-size: 11px;
    }

    .footer-bottom-bar {
        padding: 30px 0 100px;
    }

    .detail-omnibus {
        text-align: left;
    }

    main {
        padding-bottom: var(--rowGap) !important;
    }

    main.aside {
        grid-template-columns: minmax(0, 1fr);
        grid-gap: var(--rowGap) 0;
    }

    .inner {
        width: 100%;
        padding: 0 10px;
    }

    * {
        -webkit-tap-highlight-color: rgba(189, 195, 199, .75);
        -webkit-text-size-adjust: none;
    }

    body.privacy-box-visible {
        overflow: hidden;
        
        #container {
            filter: blur(5px);
            pointer-events: none;
        }
    }

    body:has(.left-side-cookies) {

        .privacy-box-trigger {
            bottom: 15px;
            width: 35px;
            height: 35px;

            &.right-side-cookies {
                right: 15px;
            }

            &.left-side-cookies {
                left: 15px;
            }
        }
    }

    .privacy-box {
        bottom: auto;
        right: auto;
        left: 15px !important;
        right: 15px !important;
        top: 50%;
        transform: translateY(-50%);
        max-width: 100vw;
        max-height: ~"calc(100% - 40px)";
    }

    input[type=text],
    input[type=password],
    input[type=email],
    select,
    textarea {
        font-size: 16px;
        padding: 0 14px;
    }

    textarea {
        padding: 10px 14px;
    }

    #facebook-box,
    .detail-loupe {
        display: none;
    }

    #detail-images {

        .swiper-button-prev,
        .swiper-button-next {
            height: 80px;
            margin-top: -40px !important;
        }

        .swiper-button-prev {
            left: -45px;
        }

        .swiper-button-next {
            right: -45px;
        }
    }

    .headline {
        font-size: 24px !important;
        margin-bottom: 10px;
    }

    #content {
        width: 100%;
        clear: both;
    }

    .sidebar {
        width: auto;
        display: contents;

        .sidebar-tabs {
            display: none;
        }
    }

    // SEARCH ENGINE
    #search-engine {
        display: none;
    }

    .newsletter-input {
        width: 100%;
    }

    .footer-content {
        flex-direction: column;
        padding: 20px 0;
    }

    .footer-item {
        width: 100% !important;
    }

    body:has(.gs-filtr-container) .filter-trigger {
        display: flex;
    }

    .filter-trigger,
    .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button {
        height: 40px;
        align-items: center;
        justify-content: space-between;
        font-weight: normal;
        font-size: 13px;
        gap: 5px;
        padding: 0 25px;
        min-width: 0;
        font-weight: bold;
        color: @header-color;
        border-radius: 20px;
        border-color: @header-color;

        &:before {
            display: none;
        }

        svg {
            position: static;
            transform: rotate(90deg);
            height: auto;
            width: 20px;
            filter: brightness(0);
        }
    }

    .dropdown-picker:before {
        top: -11px;
        font-size: 12px;
    }

    .listing-wrapper {

        .filter-trigger,
        .dropdown-picker {
            margin-top: 11px;
        }

    }

    .filter-trigger:after {
        content: 'i sortowanie';
    }

    // SORTER 
    .dropdown-picker[data-dropdown-type="sort"] {
        display: none;
    }

    .dropdown-picker:not([data-dropdown-type="sort"]) {

        &:before {
            display: block;
            color: @font-color;
            font-weight: normal;
            left: 17px;
        }

        .dropdown-button {
            min-width: 160px;
            border-color: @border-color;
            font-weight: normal;

            &:after {
                color: @font-color;
                font-weight: normal;
            }
        }

        .dropdown-listing {
            border-radius: 20px;

            label {
                padding: 5px 25px;
            }
        }
    }

    // FILTER
    #filter {
        display: none;
    }

    .product-images-mini {
        width: 100%;
        height: auto;
        margin-top: 10px;

        .swiper-wrapper {
            transform: none !important;
            width: auto;
            justify-content: center;
        }

        .swiper-slide {
            width: 10px;
            height: 10px;
            background-color: @border-color;
            border-radius: 50%;
            margin: 0 5px;
        }

        img {
            display: none !important;
        }
    }

    .user-custom-content p {
        margin-bottom: 10px;
    }

    // OTHER
    hr {
        margin: 20px 0;
    }

    .overlay-visible {
        overflow: hidden;
        height: 100dvh;
        width: 100dvw;
        position: fixed;
        top: 0;
    }

    main:not(.aside) #tree {
        display: block;
    }

    .tree-canvas {
        position: fixed;
        background: rgb(0, 0, 0) none repeat scroll 0% 0%;
        will-change: opacity;
        transition: opacity 300ms ease 0s;
        inset: 0px;
        z-index: 1001;
        pointer-events: none;
        opacity: 0;
    }

    #tree {
        position: fixed;
        top: 0;
        left: 0;
        transform: translate3d(-100%, 0, 0);
        overflow: hidden;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    :root {
        --prodGap: 15px;
        --rowGap: 30px;
        --iconSize: 18px;
    }

    // 1279 BASE

    .top-bar,
    .header-bar {
        padding-block: 4px;
    }

    .header-shipment {
        gap: 8px;
    }

    .header-shipment b {
        margin-left: -4px;
    }

    .header-contact {
        gap: 46px;
    }

    .header-contact a:before {
        left: -27px;
    }

    .header-social-links {
        gap: 8px;
        
        a:before {
            font-size: 22px;
        }
    }

    .logo {
        height: 44px;
    }

    .mainmenu {
        display: none !important;
    }

    .shop-header-container {
        padding: 8px 0;
    }

    .header-tools-wrapper {
        gap: 24px;
    }

    .shop-header {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        background-color: white;
        .shadow();
        transition: transform .2s ease-in-out;

        &.stuck {
        }
    }

    #cart-box,
    #wishlist-box,
    .log-button,
    .search-engine-trigger,
    .tree-trigger {
        padding-top: 28px;

        &:before {
            font-size: 22px;
        }

        em {
            letter-spacing: 0.1em;
            font-size: 9px;
            font-weight: 600;
            display: block;
            color: @header-color;
        }
    }

    .hamburger-icon {
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        height: 22px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        justify-content: center;
        align-items: center;

        span {
            display: block;
            width: 80%;
            height: 2px;
            background-color: @header-color;
            position: relative;

            &:nth-child(3) {

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 4px;
                    background: white;
                    height: 2px;
                    width: 2px;
                }
            }

            &:nth-child(3):after {
                right: 5px;
                left: initial;
            }
        }
    }

    #wishlist-box:after,
    #cart-box i {
        font-size: 0px;
        color: @main-color;
        width: 8px;
        height: 8px;
        top: 1px;
        right: 9px !important;
    }

    #cart-box i {
        right: 4px !important;
    }

    .newsletter-input .popup-trigger,
    .text-expand-content + button,
    .main-banner-text-container .text-wrapper > a,
    .alternating-banner .text-wrapper > a,
    .blog-banner > a,
    body[data-type="detail"] form button {
        font-size: 12px !important;
        padding: 10px 24px !important;
        border-radius: 8px;
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 32px;
        height: 32px;
        margin-top: -16px;

        &:after {
            font-size: 14px;
        }
    }

    // 1279 MAINPAGE
    .main-banner-wrapper {
        margin-bottom: calc(var(--rowGap) + 54px);
    }

    .main-banner .swiper-slide {
        height: auto;
        aspect-ratio: ~"1440/600";
    }

    .main-slider-buttons-wrapper {

        --swiper-navigation-sides-offset: 10px;
    }

    .main-banner-text-container .text-wrapper {
        bottom: 32px;

        > b {
            font-size: 38px;
        }

        > span {
            font-size: 16px;
        }

        > a {
            margin-top: 18px;
        }
    }

    .mainpage-greeting-wrapper .mainpage-greeting {
        padding: 0 2% 0 0;
    }

    .alternating-banner .text-wrapper {
        padding: 0 0 0 2%;
        margin-block: var(--rowGap);
    }

    .alternating-banner:nth-child(2n) .text-wrapper {
        padding: 0 2% 0 0;
    }

    .alternating-banner .text-wrapper > b {
        .headline();
    }

    .alternating-banner .text-wrapper > div {
        padding-bottom: 18px;
    }

    .main-page-products-wrapper,
    #detail-tabs {
        --swiper-navigation-sides-offset: 0px;

        .product {
            padding-bottom: 16px;
        }

        a.product-name {
            --fontSize: 16px;
            font-size: 16px;
        }

        .product-price {
            margin-top: 12px;
            gap: 12px;

            b {
                font-size: 20px;
            }

            del {
                font-size: 16px;
                top: -2px;
            }
        }
    }

    .main-page-products-wrapper .headline {
        .headline();
    }

    .promo-banners-wrapper {
        margin-bottom: 54px;
        gap: 24px;
    }

    .promo-banner {

        figure {
            width: 48px;
            margin-block: 20px 16px;
        }

        b {
            font-size: 15px;
        }
    }

    .blog-banners-section .headline {
        margin-bottom: 20px;
    }

    .blog-banner {

        > * {
            margin-left: 0 !important;
        }

        figure {
            margin-bottom: 12px;
        }

        > b {
            font-size: 16px;
        }

        > div {
            margin-bottom: 12px;
        }

        > a {
            margin-bottom: 12px;
        }
    }

    .main-page-products-wrapper .slider-button1 {
        top: calc(50% - 62px);
        --swiper-navigation-sides-offset: 5px;
    }

    .side-mascot {
        top: 136px;
        width: 144px;
    }

    // 1279 FOOTER
    #newsletter {
        height: auto;
        align-items: center;
    }

    .newsletter-wrapper {
        margin-block: var(--rowGap);
        padding: var(--rowGap);

        .newsletter-header b {
            font-size: 26px;
            margin-bottom: 12px;
        }
    }

    .newsletter-input input[type="text"] {
        height: 44px;
    }

    .footer-content {
        padding: 24px 0;
    }

    .footer-logo {
        margin-top: 0;
        margin-bottom: 8px;
        width: 120px;
    }

    .footer-item > strong {
        margin-bottom: 16px;
    }

    .footer-item.submenu ul {
        gap: 0;
    }

    #footer-poll ul li {
        gap: 10px;
    }

    #footer-poll ul li[data-poll-vote-id] {

        &:before {
            width: 19px;
            height: 19px;
        }

        &:after {
            width: 11px;
            height: 11px;
            margin: 0 4px;
        }
    }

    .contact-wrapper {
        columns: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;

        > * {
            margin-bottom: 0 !important;
        }
    }

    .footer-bottom-bar {
        flex-direction: column;
        gap: 16px;
        padding: 24px 0 83px;
    }

    // 1279 LISTING
    #breadcrumb {
        padding-block: 10px;
        margin-bottom: calc(-0.5 * var(--rowGap) + 54px);
    }

    .text-expand-wrapper:has(.expandable) {
        padding-bottom: 50px;
    }

    .listing-wrapper {
        gap: 16px;

        .navigation-wrapper {
            margin-bottom: 0;
        }
    }

    body[data-type="listing"] .products-list {
        gap: 0;
    }

    body[data-type="listing"] .product-info-wrapper,
    body[data-type="listing"] .product-image {
        margin-block: var(--rowGap) !important;
    }

    body[data-type="listing"] .product .product-info-wrapper {
        margin-inline: 7% 0;
        padding: 32px 64px !important;


        .product-wishlist {
            left: 10px;
            top: 10px;
            width: 32px;
            height: 32px;

            &:before {
                font-size: 18px;
            }
        }

        .product-name {
            font-size: 18px;
        }

        .product-desc {
            font-size: var(--fontSize);
        }

        .product-price {

            b {
                font-size: 18px;
            }

            del {
                margin-left: 8px;
            }
        }

        .see-more {
            font-size: 14px;
            height: 38px;

            span {
                padding: 0 28px;
            }

            &:before,
            &:after {
                bottom: -11%;
                height: 32px;
            }
        }
    }

    body[data-type="listing"] .product:nth-child(2n+1) {

        .product-info-wrapper {
            margin-inline: 0 7%;
        }
    }
    
    .listing-wrapper .pagination {
        gap: 32px;
    }

    // 1279 DETAIL
    .detail-main-wrapper {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;
    }

    #detail-images {
        align-items: center;
        flex-direction: column;
        height: auto;
        left: auto;
        margin: 0 auto;
        position: relative;
        top: auto;
        width: 100%;

        .swiper-button-prev {
            left: 10px;
            height: 32px;
        }

        .swiper-button-next {
            right: 10px;
            height: 32px;
        }
    }
    
    .product-images-mini {
        order: initial;
        height: auto;
        width: 100%;

        .size-custom {
            padding: 0 !important;
            background-color: @main-color;
        }
    }

    .detail-headline {
        text-align: center;
    }

    .detail-left,
    #detail-right {
        gap: 20px !important;
    }

    #detail-traits .trait {
        gap: 24px;

        b {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
        }

        .trait-desc {
            flex: 1;
        }
    }

    .detail-wishlist {
        padding-left: 30px;

        &:before {
            font-size: 20px;
        }
    }

    #detail-right {
        padding: 24px;
    }

    .price-wrapper {
        justify-content: center;
    }

    .detail-pricing {
        padding-bottom: 20px;
    }

    .detail-adding {

        .buy-button,
        .availability-modal-trigger,
        .detail-amount input {
            border-radius: 8px;
        }

        .detail-amount {
            margin-right: 8px;
        }
    }

    .detail-main-info {
        margin-top: 0 !important;
    }

    .detail-section-picker {
        margin-bottom: 20px;
        gap: 24px;
        top: 90px;

        .headline {
            font-size: 20px !important;
        }
    }

    .comments-list {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .comment > cite {
        padding: 20px;
        margin-bottom: 12px;
    }

    .comment-response {
        margin-block: 12px 6px;
        padding: 20px;
    }

    .under-comment {

        .added-date {
            padding-left: 12px;
            margin-left: 0;
        }

        .purchase-confirmed {
            gap: 6px;
        }
    }

    body[data-type="detail"] form {
        gap: 16px;
        margin-bottom: 10px;

        button {
            height: 38px;
        }
    }

    #detail-tabs nav {
        margin-bottom: 18px !important;
        gap: 16px;

        button {
            font-size: 24px;
        }
    }

    // iOS 12 fixes
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        .header-tools-wrapper {
            
            > * {
                margin-left: 10px;
            }
        }

        .hamburger-icon {
            .grid();
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 6px);
            gap: 0;
            justify-items: center;
            align-items: center;
        }

        .tree-canvas {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}