// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 600px) {
    .detail-share-links-wrapper span {
        width: 100%;
    }

    .stars-rating.average-rating {
        top: -2px;
    }

    .gs-tabs {

        nav {
            flex-direction: column;

            button {
                width: 100%;
            }
        }
    }

    .user-custom-content {

        iframe {
            max-width: 100%;
        }
    }

    .filter-trigger {
        padding: 0 20px;
        justify-content: flex-start;
        gap: 4px;
        width: fit-content;
    }

    .dropdown-picker[data-dropdown-type="pageSize"] {
        flex-grow: 1;

        .dropdown-button { 
            width: 100%;
        }
    }

    .dropdown-picker:not([data-dropdown-type="sort"]) {
        
        &:before {
            left: 13px;
        }
    
        .dropdown-button {
            padding: 0 36px 0 20px !important;
            min-width: 0;
        }
    }

    .privacy-box {
        padding: 10px;
        gap: 10px;
    }

    .privacy-box__header {
        gap: 10px;

        img {
            height: 20px;
        }

        strong {
            font-size: 14px;
        }
    }

    .privacy-box__actions {
        flex-wrap: wrap;

        button {
            min-height: 35px;
        }
    }

    .privacy-box__actions button.privacy-accept-all {
        width: 100%;
        order: -1;
        flex: 0 0 auto;
        min-height: 45px;
    }

    .pagination {
        gap: 4px;

        a {
            width: 30px;
            height: 30px;
        }
    }

    #breadcrumb {
        display: flex;
        margin-left: -10px;
        width: ~"calc(100% + 20px)";
        justify-content: flex-start;
        position: relative;

        .breadcrumb-end {
            display: block;
            position: absolute;
            top: 0;
            top: 0;
            height: 100%;
            width: 20px;
            pointer-events: none;

            &:nth-of-type(1) {
                left: -7px;
                background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgb(255, 255, 255));
            }

            &:nth-of-type(2) {
                right: -7px;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgb(255, 255, 255));
            }
        }

        ul {
            flex-wrap: nowrap;
            overflow-y: auto;
            justify-content: flex-start;
            flex-direction: row-reverse;
            padding-bottom: 5px;
        }

        li {
            white-space: nowrap;

            &:last-of-type {
                padding-right: 10px;
            }

            &:first-of-type {
                padding-left: 10px;
            }

            &:nth-of-type(1) {
                order: 10;
            }

            &:nth-of-type(2) {
                order: 9;
            }

            &:nth-of-type(3) {
                order: 8;
            }

            &:nth-of-type(4) {
                order: 7;
            }

            &:nth-of-type(5) {
                order: 6;
            }
        }
    }

    #detail-images .swiper-button-prev {
        left: 0;
    }

    #detail-images .swiper-button-next {
        right: 0;
    }

    #gsGalleryContainer > a {
        width: 100%;
    }

    .navigation-wrapper {
        padding-top: 0;
        border-top: 0;
    }

    .availability-modal-wrapper .wrapper {
        justify-content: center;
        gap: 10px;

        > p {
            text-align: center;
        }

        > .availability-product {
            justify-content: center;
        }
    }

    .availability-modal-wrapper .availability-product {
        gap: 10px;
        padding: 10px 0;
    }

    .availability-modal-wrapper .input-wrapper {
        flex-wrap: wrap;

        > * {
            width: 100%;
        }
    }

    .product-images {
        width: 100%;
    }

    .product-images .size-full {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
    }

    .detail-amount {
        width: 100%;
        margin: 0 0 8px 0 !important;
    }

    .pills-group:before,
    .remove-all-filters {
        font-size: 11px;
    }

    .single-pill {
        font-size: 11px;
        padding: 2px 5px;
    }

    #pills-container:not(:empty) {
        margin-bottom: 20px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    :root {
        --rowGap: 25px;
    }

    // 600 BASE

    .headline,
    .main-page-products-wrapper .headline,
    .alternating-banner .text-wrapper > b,
    #detail-tabs nav button {
        font-size: 20px !important;
    }

    @media (max-width: 420px) {
        .top-bar {
            font-size: 11px;
        }

        .header-contact:has(.email) {

            .phone {
                display: none;
            }

            .email {
                display: block;
            }
        }

        .header-contact:has(.phone) {

            .email {
                display: none;
            }

            .phone {
                display: block !important;
            }
        }

        .shop-header-container {
            flex-direction: column;
            gap: 4px;
            padding-block: 6px 6px;
        }

        .header-tools-wrapper {
            width: 100%;
            justify-content: space-evenly;
        }

        .detail-section-picker {
            top: 134px;
        }
    }

    // 600 MAINPAGE

    .main-banner-text-container .text-wrapper {
        bottom: 10px;

        > b {
            font-size: 18px;
        }

        > span {
            display: none;
        }

        > a {
            margin-top: 0;
        }
    }

    .side-mascot {
        display: none !important;
    }

    .blog-banners-wrapper {
        gap: 12px;
    }

    .blog-banner b {
        font-size: 14px;
    }

    @media (max-width: 420px) {
        .blog-banners-wrapper {
            grid-template-columns: minmax(0, 1fr);
        }
    }

    .main-page-products-wrapper a.product-name,
    #detail-tabs a.product-name {
        --fontSize: 14px;
        font-size: 14px;
    }

    // 600 FOOTER
    .newsletter-wrapper {

        .newsletter-header b {
            font-size: 20px !important;
        }
    }

    // 600 LISTING

    body[data-type="listing"] .product .product-info-wrapper .see-more span {
        white-space: nowrap;
    }

    // 600 DETAIL

    .detail-share-links-wrapper span {
        display: flex;
        justify-content: center;
    }

    .price-wrapper {
        gap: 8px;
    }

    @media (max-width: 360px) {
        .price-wrapper b {
            font-size: 24px;
        }
    }

    .detail-section-picker {
        gap: 12px;
        padding: 16px 8px;

        .headline {
            font-size: 16px !important;
        }
    }

    @media (max-width: 460px) {
        .detail-section-picker {
            padding: 16px 0;
            gap: 8px;

            &:before {
                .full-bg();
                background: @light-bg;
            }

            .headline {
                font-size: 14px !important;
            }

            @media (max-width: 410px) {
                gap: 6px;

                .headline {
                    font-size: 12px !important;
                }
            }
        }
    }

    .main-info-sections .headline {
        font-size: 18px !important;
    }

    .under-comment {
        .grid();
        grid-template-columns: minmax(0, 55fr) minmax(0, 45fr);
        padding: 0;
        gap: 6px;
        margin-top: -4px;

        .author {
            grid-row: 1 / 2;
            grid-column: 1 / 2;
            align-self: end;
            position: relative;
            top: -3px;
        }

        .stars-rating {
            grid-row: 1 / 2;
            grid-column: 2 / 3;
            margin-left: auto;
        }

        .added-date {
            margin: 0;
            padding: 0;
            border-left: none;
            grid-row: 2 / 3;
            grid-column: 1 / 2;
            align-self: start;
        }

        .purchase-confirmed {
            grid-row: 2 / 3;
            grid-column: 2 / 3;
            white-space: nowrap;

            &:before {
                display: none;
            }
        }
    }
}